<template>
  <b-row class="match-height risk-procedure-tab">
    <b-col md="4">
      <procedure ref="procedure"></procedure>
    </b-col>
    <b-col md="8">
      <CRS-score ref="crs_score"></CRS-score>
    </b-col>
    <b-col md="8">
      <tri-score ref="tri_score"></tri-score>
    </b-col>
    <b-col md="4">
      <meld-STS-score ref="meld_sts_score"></meld-STS-score>
    </b-col>
    <b-col cols="12">
      <euro-SCOREII ref="euro_score_ii"></euro-SCOREII>
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard } from "bootstrap-vue";
import Procedure from "./risk-procedure/Procedure";
import CRSScore from "./risk-procedure/CRSScore";
import TriScore from "./risk-procedure/TriScore";
import MeldSTSScore from "./risk-procedure/MeldSTSScore";
import EuroSCOREII from "./risk-procedure/EuroSCOREII";
// import Clinical from './followup/Clinical'
// import Echo from './followup/Echo'
import { mapState, mapActions } from "vuex";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import config from "@/config";
import { getDiff } from "@core/utils/utils";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    Procedure,
    CRSScore,
    TriScore,
    MeldSTSScore,
    EuroSCOREII,
  },
  timers: { updatePatient: config.TIMER_OPTION },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
  },
  methods: {
    ...mapActions("patient", ["updatePatientById"]),
    updatePatient() {
      if (this.readOnly) {
        return;
      }

      let activeElement = document.activeElement;
      let blockingElements = ["input", "textarea"];
      let isFlatPickrInput =
        activeElement && activeElement.classList.contains("flatpickr-input");

      // check whether an input field is the active elemnent,
      //if it is, do not interrupt the users entries with updating
      // the patientInfo but return instead
      if (
        activeElement &&
        blockingElements.indexOf(activeElement.tagName.toLowerCase()) !== -1 &&
        !isFlatPickrInput // still save, if the active element is a flatpickr element and thus a date field
      ) {
        return;
      }

      let patientInfo = {
        ...getDiff(this.$refs.procedure.patientInfo, this.patient),
        ...getDiff(this.$refs.crs_score.patientInfo, this.patient),
        ...getDiff(this.$refs.tri_score.patientInfo, this.patient),
        ...getDiff(this.$refs.meld_sts_score.patientInfo, this.patient),
        ...getDiff(this.$refs.euro_score_ii.patientInfo, this.patient),
        _id: this.patient._id,
      };

      this.updatePatientById(patientInfo).catch((err) => {
        this.showToast(
          "danger",
          "Error",
          "An error occured while updating the patient."
        );
      });
    },
    showToast(variant, title, content) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: title,
          icon: "BellIcon",
          text: content,
          variant,
        },
      });
    },
  },
};
</script>

<style lang="scss">
.risk-procedure-tab {
  .vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }
  .bordered-card {
    border: 1px solid rgba(34, 41, 47, 0.125);
  }
}
</style>
