<template>
  <b-card
    title="Meld + STS score"
    sub-title="Information about Meld + STS score"
  >
    <b-row>
      <b-col cols="12">
        <b-card class="bordered-card" title="Meld score">
          <b-row>
            <b-col cols="12">
              <b-button
                variant="outline-secondary"
                block
                v-ripple.400="'rgba(100, 100, 100, 0.15)'"
                @click="calcMeldScore"
              >
                Calculate
              </b-button>
            </b-col>
            <b-col cols="12" class="mt-1">
              <b-form-group
                label="Creatinin [ml/dl]"
                label-for="preop_lab_creatinin"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_lab_creatinin"
                  id="preop_lab_creatinin"
                  type="number"
                  placeholder="Creatinin [ml/dl]"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-1">
              <b-form-group
                label="Bilirubin [mg/dl]"
                label-for="preop_lab_bilirubin"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_lab_bilirubin"
                  id="preop_lab_bilirubin"
                  type="number"
                  placeholder="Bilirubin [mg/dl]"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-1">
              <b-form-group
                label="INR"
                label-for="preop_lab_inr"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.preop_lab_inr"
                  id="preop_lab_inr"
                  type="number"
                  placeholder="INR"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Dialysis in last 7 days"
                label-for="risk_scores_preop_meld_score_dialysis_within_last_7_days"
                label-cols-md="4"
              >
                <v-select
                  v-model="
                    patientInfo.risk_scores_preop_meld_score_dialysis_within_last_7_days
                  "
                  label="value"
                  :options="options"
                  :disabled="readOnly"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                label="Meld score:"
                label-for="risk_scores_preop_meld_score_calculated"
                label-cols-md="4"
              >
                <div class="vertical-align">
                  {{ patientInfo.risk_scores_preop_meld_score_calculated }}
                </div>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Meld score"
                label-for="risk_scores_preop_meld_score"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.risk_scores_preop_meld_score"
                  id="risk_scores_preop_meld_score"
                  type="number"
                  placeholder="Score"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="12">
        <b-card class="bordered-card" title="STS score">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label="STS score"
                label-for="risk_scores_preop_STS_score"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.risk_scores_preop_STS_score"
                  id="risk_scores_preop_STS_score"
                  type="number"
                  placeholder="STS score"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-1">
              <b-form-group
                label="STS Triscore Mortality [%]"
                label-for="risk_scores_sts_triscore_mortality"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="patientInfo.risk_scores_sts_triscore_mortality"
                  id="risk_scores_sts_triscore_mortality"
                  type="number"
                  placeholder="STS Triscore Mortality [%]"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12" class="mt-1">
              <b-form-group
                label="STS Triscore Morbidity Mortality [%]"
                label-for="risk_scores_sts_triscore_morbidity_mortality"
                label-cols-md="4"
              >
                <b-form-input
                  v-model="
                    patientInfo.risk_scores_sts_triscore_morbidity_mortality
                  "
                  id="risk_scores_sts_triscore_morbidity_mortality"
                  type="number"
                  placeholder="STS Triscore Morbidity Mortality [%]"
                  :readonly="readOnly"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { dateMask } from "@core/utils/utils";
import { mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import { getMeldScoreForRiskProcedure } from "@/@core/utils/utils";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormDatepicker,
    BButton,
    vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      patientInfo: {
        _id: null,
        preop_lab_creatinin: "",
        preop_lab_bilirubin: "",
        preop_lab_inr: "",
        risk_scores_preop_meld_score_dialysis_within_last_7_days: "",
        risk_scores_preop_meld_score: "",
        risk_scores_preop_STS_score: "",
        risk_scores_preop_meld_score_calculated: "",
        risk_scores_sts_triscore_mortality: "",
        risk_scores_sts_triscore_morbidity_mortality: "",
      },
      options: ["yes", "no"],
    };
  },
  computed: {
    ...mapState("patient", ["patient", "role"]),
    readOnly() {
      return this.role == "viewer" || this.role == "";
    },
  },
  watch: {
    patient(v, ov) {
      if (v._id) {
        this.setPatientInfo(v);
      }
    },
  },
  mounted() {
    if (this.patient._id) {
      this.setPatientInfo(this.patient);
    }
  },
  methods: {
    dateMask,
    setPatientInfo(v) {
      this.patientInfo._id = v._id;

      this.patientInfo.preop_lab_creatinin = v.preop_lab_creatinin;
      this.patientInfo.preop_lab_bilirubin = v.preop_lab_bilirubin;
      this.patientInfo.preop_lab_inr = v.preop_lab_inr;
      this.patientInfo.risk_scores_preop_meld_score_dialysis_within_last_7_days =
        v.risk_scores_preop_meld_score_dialysis_within_last_7_days;
      this.patientInfo.risk_scores_preop_meld_score =
        v.risk_scores_preop_meld_score;
      this.patientInfo.risk_scores_preop_meld_score_calculated =
        v.risk_scores_preop_meld_score_calculated;
      this.patientInfo.risk_scores_preop_STS_score =
        v.risk_scores_preop_STS_score;
      this.patientInfo.risk_scores_sts_triscore_mortality =
        v.risk_scores_sts_triscore_mortality;
      this.patientInfo.risk_scores_sts_triscore_morbidity_mortality =
        v.risk_scores_sts_triscore_morbidity_mortality;
    },
    calcMeldScore() {
      let score = getMeldScoreForRiskProcedure(
        this.patientInfo.preop_lab_creatinin,
        this.patientInfo.preop_lab_bilirubin,
        this.patientInfo.preop_lab_inr,
        this.patientInfo
          .risk_scores_preop_meld_score_dialysis_within_last_7_days
      );
      this.patientInfo.risk_scores_preop_meld_score_calculated = score;
    },
  },
};
</script>

<style>
</style>
